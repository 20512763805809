<template>
  <div style="width: 100%">
    <div style="width: 100%; padding: 20px 0px">
      <el-steps
        style="width: 100%"
        align-center
        finish-status="success"
        space="25%"
        :active="StepsActive"
      >
        <el-step :title="tools.GetLanguageValue('web.选择客户')" />
        <el-step :title="tools.GetLanguageValue('web.创建单据')" />
        <el-step :title="tools.GetLanguageValue('web.编辑单据')" />
        <el-step :title="tools.GetLanguageValue('web.完成')" />
      </el-steps>
    </div>

    <div
      style="width: 100%; margin-top: 20px; text-align: center"
      v-if="StepsActive == 0"
    >
      <div>
        <el-select
          v-model="userSeletvalue"
          filterable
          remote
          reserve-keyword
          :placeholder="tools.GetLanguageValue('web.请输入客户信息')"
          :remote-method="remoteMethod"
          :loading="userListloading"
          @change="userSelected"
          style="width: 350px; margin-left: 3px"
        >
          <el-option
            style="width: 400px"
            v-for="item in userListoptions"
            :key="item.Id"
            :label="item.Name"
            :value="item"
          >
            <div style="display: flex; justify-content: space-between">
              <div style="width: 160px; padding: 0px 5px" class="fonthidden">
                {{ item.Name }}
              </div>
              <div style="width: 100px" class="fonthidden">
                {{ item.PhoneNumber }}
              </div>
              <div style="width: 100px; margin-left: 10px" class="fonthidden">
                {{ item.DocNumber }}
              </div>
            </div>
            <span> {{ item.Name + item.DocNumber }}</span>
          </el-option>

          <template #prefix>
            <el-icon><Search /></el-icon>
          </template>
        </el-select>
      </div>



      <el-button
        style="width: 160px; height: 40px; margin-top: 70px"
     
        @click="skipNewUser"
      >
        {{ tools.GetLanguageValue("web.跳过") }}
      </el-button>

      <el-button
        style="width: 200px; height: 40px; margin-top: 70px"
        type="primary "
        @click="CreateNewUser"
      >
        {{ tools.GetLanguageValue("web.创建新客户") }}
      </el-button>



    </div>

    <el-form
      :model="userForm"
      v-if="StepsActive == 1"
      style="max-width: 1300px; margin: 0 auto; margin-top: 20px"
    >
      <el-form-item style="padding: 0px; margin: 0px">
        <el-col :span="8">
          <el-input
            class="userInfoInput_userCreate"
            :placeholder="tools.GetLanguageValue('web.姓名')"
            v-model="userForm.Name"
          >
            <template #prefix>
              <el-icon><Avatar /></el-icon>
            </template>
          </el-input>
        </el-col>
        <el-col :span="8">
          <el-input
            class="userInfoInput_userCreate"
            :placeholder="tools.GetLanguageValue('web.电话')"
            v-model="userForm.PhoneNumber"
          >
            <template #prefix>
              <el-icon><PhoneFilled /></el-icon>
            </template>
          </el-input>
        </el-col>

        <el-col :span="8">
          <el-input
            :placeholder="tools.GetLanguageValue('web.证件号')"
            class="userInfoInput_userCreate"
            v-model="userForm.DocNumber"
          >
            <template #prefix>
              <el-icon><Postcard /></el-icon>
            </template>
          </el-input>
        </el-col>
      </el-form-item>

      <el-form-item>
        <el-col :span="8">
          <el-input
            :placeholder="tools.GetLanguageValue('web.地址')"
            class="userInfoInput_userCreate"
            v-model="userForm.Address"
          >
            <template #prefix>
              <el-icon><Location /></el-icon>
            </template>
          </el-input>
        </el-col>

        <el-col :span="8">
          <el-input
            class="userInfoInput_userCreate"
            :placeholder="tools.GetLanguageValue('web.电子邮件')"
            v-model="userForm.Email"
          >
            <template #prefix>
              <el-icon><Message /></el-icon>
            </template>
          </el-input>
        </el-col>
        <el-col :span="8">
          <div
            style="
              height: 60px;
              line-height: 60px;
              display: flex;
              align-items: center;
            "
          >
            <el-select
              v-model="userForm.Level"
              class="userInfoInput_userCreate"
              style="min-width: 50%"
            >
              <template #prefix>
                <el-icon><Star /></el-icon>
              </template>
              <el-option
                v-for="item in clientLevelOptions"
                :key="item.Value"
                :label="item.Value"
                :value="item.Key"
              ></el-option>
            </el-select>

            <!-- <el-button
              v-if="
                userForm.Id == '00000000-0000-0000-0000-000000000000' ||
                userForm.Id == '' ||
                userForm.Id == undefined ||
                userForm.Id == 'undefined'
              "
              type="danger"
              @click="addClientWithRepairInfo('add')"
            >
              {{ tools.GetLanguageValue("web.保存为新客户") }}</el-button
            >
            <el-button
              v-else
              type="primary"
              @click="addClientWithRepairInfo('update')"
            >
              {{ tools.GetLanguageValue("web.客户信息更改") }}</el-button
            > -->
          </div>
        </el-col>
      </el-form-item>

      <!-- <el-input
            class="userInfoInput"
            :placeholder="tools.GetLanguageValue('web.最后创建时间')"
            v-model="repairModel.LastCreateTime"
          >
            <template #prefix>
              <el-icon><PhoneFilled /></el-icon>
            </template>
          </el-input>

          <el-input
            class="userInfoInput"
            :placeholder="tools.GetLanguageValue('web.总维修数量')"
            v-model="repairModel.Count"
          >
            <template #prefix>
              <el-icon><PhoneFilled /></el-icon>
            </template>
          </el-input> -->
      <el-form label-width="230px" v-if="repairModel.Count != ''">
        <el-form-item :label="tools.GetLanguageValue('web.上次维修时间')">
          <el-col :span="24">
            <el-input
              disabled
              :placeholder="tools.GetLanguageValue('web.上次维修时间')"
              class="userInfoInput_userCreate"
              v-model="repairModel.LastCreateTime"
            >
            </el-input>
          </el-col>
        </el-form-item>

        <el-form-item :label="tools.GetLanguageValue('web.总维修数量')">
          <el-col :span="24">
            <el-input
              disabled
              :placeholder="tools.GetLanguageValue('web.总维修数量')"
              class="userInfoInput_userCreate"
              v-model="repairModel.Count"
            >
            </el-input>
          </el-col>
        </el-form-item>
      </el-form>
      <div style="width: 100%; text-align: center; margin-top: 20px">
        <!-- <el-button style="width: 200px; height: 45px" @click="StepsActive = 0">
          上一步
        </el-button> -->
        <el-button
          style="width: 200px; height: 45px"
          type="primary "
          :disabled="IsDisabled"
          @click="CreateRepairDocument"
        >
          {{ tools.GetLanguageValue("web.创建维修单") }}
        </el-button>
      </div>
    </el-form>
  </div>

  <div
    v-if="StepsActive == 2"
    style="width: 100%; text-align: center; margin-top: 20px"
  >
    <el-button
      style="width: 200px; height: 45px"
      @click="CreateRepairDocument"
      :disabled="IsDisabled"
    >
      {{ tools.GetLanguageValue("web.创建维修单") }}
    </el-button>
    <el-button
      style="width: 200px; height: 45px"
      @click="CreateRepairDocument"
      :disabled="IsDisabled"
    >
      {{ tools.GetLanguageValue("web.创建维修单") }}
    </el-button>
  </div>
 
  <div style="width: calc(100% - 10px)" v-if="false">
    <el-table
      class="dataTable custom-title-font"
      border
      :row-class-name="tableRowClassName"
      :data="repairList"
      style="width: calc(100%)"
      @cell-click="handleCellClick"
      row-key="Id"
      :height="ContentHeight + 'px'"
      default-expand-all
      :tree-props="{
        children: 'RepairMobileInfos',
        hasChildren: 'hasChildren',
      }"
    >
      <el-table-column
        prop="RepairNo"
        width="140"
        class-name="padding0item"
        :label="tools.GetLanguageValue('web.单号')"
      >
        <template #default="{ row }">
          <span v-if="row.IsReworking" style="color: red">
            {{ row.RepairNo }}
          </span>
          <span v-if="!row.IsReworking">
            {{ row.RepairNo }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        class-name="padding0item"
        prop="ClientName"
        width="140"
        :label="tools.GetLanguageValue('web.客户姓名')"
      >
        <template #default="{ row }">
          <span>{{ row.ClientName }} </span>
        </template>
      </el-table-column>
      <el-table-column
        class-name="padding0item"
        prop="ClientName"
        width="110"
        :label="tools.GetLanguageValue('web.手机')"
      >
        <template #default="{ row }">
          <span>{{ row.PhoneNumber }} </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="BrandAndModel"
        class-name="padding0item"
        :label="tools.GetLanguageValue('web.维修品牌')"
        width="180"
      >
        <template #default="{ row }">
          <el-tag
            style="margin: 3px"
            v-if="row.BrandAndModel != null && row.BrandAndModel.length > 0"
            type="info"
            >{{ row.BrandAndModel }}</el-tag
          >
          <el-tag
            style="margin: 3px"
            v-if="row.ModelName != null && row.ModelName.length > 0"
            >{{ row.ModelName }}</el-tag
          >
        </template>
      </el-table-column>
      <el-table-column
        class-name="padding0item"
        prop="RepairProblems"
        :label="tools.GetLanguageValue('web.维修问题/服务')"
        width="170"
      >
        <template #default="{ row }">
          <el-tag
            size="small"
            v-show="index < 3"
            style="margin: 3px"
            v-for="(item, index) in row.RepairProblems"
            :key="item"
          >
            {{ item }}
          </el-tag>
          <span
            v-if="row.RepairProblems != null && row.RepairProblems.length >= 3"
          >
            ...
          </span>
        </template>
      </el-table-column>
      <!-- <el-table-column
            prop="TotalPrice"
            label="维修总金额"
            width="120"
          ></el-table-column> -->
      <!-- <el-table-column
          prop="ImelNumber"
          class-name="padding0item"
          label="IMEI"
          width="160"
        ></el-table-column> -->

      <el-table-column
        prop="RepairStartTimeFormat"
        class-name="padding0item"
        :label="tools.GetLanguageValue('web.维修时间')"
        width="160"
      ></el-table-column>
      <el-table-column
        class-name="padding0item"
        prop="WarrantyStartTimeFormat"
        :label="tools.GetLanguageValue('web.保修开始日期')"
        width="160"
      ></el-table-column>
      <el-table-column
        prop="RepairOperatorName"
        class-name="padding0item"
        :label="tools.GetLanguageValue('web.创建人')"
        width="150"
      >
        <template #default="{ row }">
          <p
            style="
              width: 120px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              -o-text-overflow: ellipsis;
            "
          >
            {{ row.RepairOperatorName }}
          </p>
        </template>
      </el-table-column>
      <el-table-column
        :label="tools.GetLanguageValue('web.状态')"
        fixed="right"
        class-name="padding0item"
        width="190"
      >
        <template #default="{ row }">
          <div style="display: flex; gap: 5px" v-if="row.Tree != 'YES'">
            <el-select
              size="small"
              v-model="row.Repairstatus"
              @change="changeStatus(row)"
              style="width: 120px"
            >
              <el-option
                v-for="item in repairstatusOptions"
                :key="item.Value"
                :label="item.Value"
                :value="item.Key"
              >
                {{ item.Value }}
              </el-option>
            </el-select>

            <el-button
              type="primary"
              size="small"
              @click="CutRepairstatus(row)"
            >
              <el-icon>
                <refresh />
              </el-icon>
            </el-button>
          </div>
        </template>
      </el-table-column>
    </el-table>
    <div style="display: flex; align-items: center; height: 50px"></div>
  </div>
</template>
  <script setup>
import { useRouter, useRoute } from "vue-router";
import axios from "../../../commons/AxiosMethod.js";
import navigation from "../../../components/Modules/Navigation.vue";
import {
  ref,
  watch,
  onBeforeMount,
  onUnmounted,
  onMounted,
  reactive,
  nextTick,
} from "vue";
import tools from "../../../commons/tools.js";
import { ElMessage, ElMessageBox } from "element-plus";
import {
  Search,
  Plus,
  ChatLineRound,
  Tickets,
  Hide,
  CircleCloseFilled,
  Coin,
  Calendar,
  Management,
  CirclePlusFilled,
  CircleCheck,
  Goods,
  Close,
  Apple,
  Delete,
  CircleClose,
  Lock,
  DeleteFilled,
  LocationFilled,
  CirclePlus,
  Cpu,
  FolderAdd,
  Document,
  Warning,
  Refresh,
  Avatar,
  Postcard,
  CreditCard,
  Position,
  Location,
  Star,
  InfoFilled,
  PhoneFilled,
  Message,
} from "@element-plus/icons-vue";

const clientWidth = document.body.clientWidth;
const clientHeight = document.documentElement.clientHeight;

const route = useRoute();
const router = useRouter();

const userForm = ref({
  Id: undefined,
}); // 用户数据

const userListoptions = ref(); // 查询用户选项列表

const selectInput = ref("");
const selectInputRef = ref(null);
const userSeletvalue = ref(null); // 从查询中选择的用户

// 页面加载
onMounted(() => {
  ClientLevelEnum();
});

//监听 value 的变化
watch(selectInput, (newVal, oldVal) => {
  if (newVal.includes("/")) {
    // 如果 value 包含 '/'，则设置焦点到 input 上
    nextTick(() => {
      if (selectInputRef.value) {
        selectInputRef.value.focus();
      }
    });
  }
});

//切换到下一状态
const CutRepairstatus = (item) => {
  axios.apiMethod("/stores/Repair/CutRepairstatus", "post", item, (result) => {
    if (result.Data.IsSuccess) {
      getRepairList(userForm.value.Id);
    } else {
      ElMessage({
        message: result.Data.Msg,
        type: "error",
      });
    }
  });
};

const repairList = ref([]);
const repairModel = ref({
  Count: 0,
  LastCreateTime: "",
});

// 获取维修单列表
const getRepairList = (clientId) => {
  axios.apiMethod(
    "/stores/Repair/GetRepairByUserId",
    "get",
    {
      clientId: clientId,
    },
    (result) => {
      repairModel.value = result.Data;
    }
  );
};

// 选择改变
const changeStatus = (row) => {
  // 改变之后调用更新方法
  axios.apiMethod(
    "/stores/Repair/UpdateRepairMobileStatus",
    "post",
    row,
    (result) => {
      if (result.Data.IsSuccess) {
        ElMessage({
          message: tools.GetLanguageValue("web.操作成功"),
          type: "success",
        });
      } else {
        ElMessage({
          message: result.Data.Msg,
          type: "error",
        });
      }
      getRepairList(userForm.value.Id);
    }
  );
};

const repairstatusOptions = ref([]);

// 状态下拉列表初始化
const getRepairstatusEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetRepairstatusEunm",
    "get",
    null,
    (result) => {
      console.log(result.Data);
      repairstatusOptions.value = result.Data;
    }
  );
};

const UserCollapseOpt = ref("1");

const userListloading = ref(false); // 查询用户loading

// 远程查询用户
const remoteMethod = (query, cb) => {
  selectInput.value = query;
  if (query) {
    if (query.length < 0) {
      return;
    }

    if (query.indexOf("/") > -1) {
      return;
    }

    userListloading.value = true;
    axios.apiMethod(
      "/stores/Client/GetClientByAnyWords?anyWords=" + query,
      "get",
      {},
      (result) => {
        userListoptions.value = result.Data;
        userListloading.value = false;
      }
    );
  } else {
    userListoptions.value = [];
  }
};
 
  
const StepsActive = ref(0);

const userSelected = () => {
  // 1选中的用户和当前用户是不是同一个用户
  // 是同一个用户?啥都不用动
  if (userForm.value.Id !== userSeletvalue.value.Id) {
    // 2不是同一个用户--维修单就需要置空，客户Id就要改为当前选中的
    userForm.value = JSON.parse(JSON.stringify(userSeletvalue.value));
    // repairForm.value.Id = undefined // 维修单Id清空
    // repairForm.value.ClientId = userForm.value.Id; // 维修单的客户id清空
    // repairItemDataInfo.value = [] // 清空维修的项目
    // totalAllPrice.value = 0;
    userSeletvalue.value = "";
    StepsActive.value = 1;
    getRepairList(userForm.value.Id);
    //查询所有的订单
  }
};

//创建新用户
const CreateNewUser = () => {
  StepsActive.value = 1;
};


//跳过创建客户，直接去维修单页面
const skipNewUser = () => { 
  router.push({
    name: "repairDetail",
    query: { id: guid()  },
  });
};
  
const IsDisabled = ref(false);

//创建维修单
const CreateRepairDocument = () => {
  var item = {
    ClientInfo: userForm.value, 
    Id: guid(),
  };
  IsDisabled.value = true;

  axios.apiMethod(
    "/stores/Repair/InsertRepairByUserId",
    "post",
    item,
    (result) => {
      IsDisabled.value = false;
      if (result.Data.IsSuccess) {
        router.push({
          name: "repairDetail",
          query: { id: result.Data.Model, itemId: null },
        });
        StepsActive.value = 2;
      } else {
        ElMessage({
          message: result.Data.Msg,
          type: "error",
        });
      }
    }
  );
};

// 清空用户信息
const clearUserForm = () => {
  userForm.value = {};
  userForm.value.Id = undefined;
  //   repairForm.value.ClientId = "00000000-0000-0000-0000-000000000000";
};

const S4 = () => {
  return (((1 + Math.random()) * 0x10000) | 0).toString(16).substring(1);
};
const guid = () => {
  return (
    S4() +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    "-" +
    S4() +
    S4() +
    S4()
  );
};
const clientLevelOptions = ref([]);
// 获取客户级别
const ClientLevelEnum = () => {
  axios.apiMethod(
    "/sys/SystemData/GetClientLevelEnum",
    "get",
    null,
    (result) => {
      clientLevelOptions.value = result.Data;
    }
  );
};
</script>
  <style  lang="scss">
.dataTable .el-table__row {
  cursor: pointer;
}
.repairstatus_item {
  margin: 5px;
}
</style>
  <style>
.el-table .warning-row {
  --el-table-tr-bg-color: var(--el-color-warning-light-9);
}
.el-table .success-row {
  --el-table-tr-bg-color: var(--el-color-success-light-9);
}
.padding0item {
  padding: 0px !important;
  height: 30px;
}
/* .el-select {
  .select-trigger {
    .el-select__input {
      min-width: 5px;
    }
  }
} */
.floating-save-button {
  position: fixed;
  bottom: 2px;
  right: 20px;
  z-index: 1000;
}
.input {
  margin: 5px 0px;
}

.userInfoInput_userCreate {
  padding: 5px;
  width: 95%;
}
.el-collapse {
  border: none;
  margin-left: -1px; /* 调整间距以消除边框影响 */
}

.el-collapse-item__header {
  border: none;
}

.el-collapse-item__header .el-collapse-item__arrow {
  display: none; /* 隐藏箭头图标 */
}

.el-collapse-item__wrap {
  border: none;
}
.el-card__body {
  padding: 5px 35px;
}
</style>